import React from 'react'
import cx from 'classnames'

import styles from './notifications.module.scss'

import Notification from './Notification'

import { useNotifications } from '../../lib/useNotifications'


const Notifications = () => {
  const context = useNotifications()

  const allWidthIsExist = Object.keys(context?.state || {}).find(
    (key: string) => context?.state[key]?.options?.allWidth,
  )

  return (
    <>
      {allWidthIsExist ? (
        <div className={styles.wrapperAllWidth}>
          <div className={cx(styles.inner)}>
            {Object.keys(context?.state || {})
              .filter((key: string) => context?.state[key]?.options?.allWidth)
              .map((key: string) => (
                <React.Fragment key={key}>
                  {context?.state[key] ? (
                    <Notification {...context?.state[key]} />
                  ) : null}
                </React.Fragment>
              ))}
          </div>
        </div>
      ) : null}
      <div className={styles.wrapper}>
        <div className={cx(styles.inner)}>
          {Object.keys(context?.state || {})
            .filter((key: string) => !context?.state[key]?.options?.allWidth)
            .map((key: string) => (
              <React.Fragment key={key}>
                {context?.state[key] ? (
                  <Notification {...context?.state[key]} />
                ) : null}
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  )
}

export default Notifications
