import React, { ElementType } from 'react'
import cx from 'classnames'
import styles from './Flex.module.scss'
import { GridPropGap } from '@consta/uikit/Grid'

interface FlexProps {
  as?: ElementType,
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around'
  align?: 'start' | 'end' | 'center'
  gap?: GridPropGap
  children: React.ReactNode
  className?: string
}

export const Flex = (props: FlexProps) => {
  const {
    as: Tag = 'div',
    gap,
    align,
    justifyContent,
    direction,
    className
  } = props
	return (
		<Tag
      className={cx(
        styles.Flex,
        className,
        { [styles[`gap${gap}`]]: Boolean(gap) },
        { [styles[`align${align}`]]: Boolean(align) },
        { [styles[`justifyContent${justifyContent}`]]: Boolean(justifyContent) },
        { [styles[`direction${direction}`]]: Boolean(direction) },
      )}
    >
      {props.children}
		</Tag>
	);
};
