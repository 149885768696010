
export const API_BASE_URL = 'https://api-wms-test.tempoline.ru'
export const LOCAL_STORAGE_NAME = '____TEMPOLINE___WMS____'
export const SETTINGS_LOCAL_STORAGE_NAME = '____SETTINGS___WMS____'
export const QZ_HOST = 'localhost'


export const NOTIFICATIONS_TIMEOUT = 3000



// todo заменить / изменить / переместить
export const selfPickupDeliveryServices = ['SELF_PICKUP'] //ID курьерок которые в модуле формирования транспортного места считаются самовывозом

// todo куда-то перенести
export const ShipmentTypes = {
  DELIVERY_SERVICE: 'Курьерская служба',
  FBO: 'ФБО',
  SELF_PICKUP: 'Самовывоз',
  FBS: 'МП ФБС',
}
